@import "src/assets/styles/variables.scss";
@import "src/assets/styles/global-style.scss";

mat-dialog-container {
  border-radius: 4px !important;
  background-color: rgba(0, 0, 0, 0.8) !important;
  border: 1px solid #3369ff;
  position: relative;
  padding: 0 !important;

  .mat-mdc-dialog-surface {
    box-shadow: none !important;
    background-color: transparent !important;
  }

  .mdc-dialog__surface::before {
    display: none !important;
  }

  .header-modal-content {
    @include flex-center;

    position: relative;
    padding: 14px;

    .close-btn {
      @extend .x28;
      @include bg-view;

      background-image: url("#{$fs-icons-path}/close-24.svg");
      position: absolute;
      right: 8px;
      top: 8px;
    }

    .modal-badge {
      color: $input-border-color;
      font-size: 20px;
      font-weight: 400;
      position: absolute;
      left: 30px;
      top: 22px;
    }

    h1 {
      font-size: 28px;
      font-weight: 400;
      text-transform: uppercase;
      width: max-content;
    }

    .modal-title {
      @include d-flex;

      text-align: center;
      border-bottom: 2px solid $prm-color;
      padding-bottom: 3px;
      padding-inline: 0;
      margin-bottom: 0px;
      color: $white-clr;
    }

    .modal-icon {
      position: absolute;
      left: 20px;
      top: 16px;
    }
  }

  .modal-main-div {
    max-height: 40rem;
    position: relative;

    .modal-header-div {
      width: 100%;
      top: -24px;
      padding-bottom: 14px;
      margin-top: -14px;
      margin-bottom: 18px;
      border-bottom: 2px solid $prm-color;
      position: sticky;
      left: 0;
      z-index: 10;
      background-color: black !important;
    }

    h2 {
      margin-top: 6px;
    }
  }

  .action-buttons {
    @extend .flex-center;

    position: relative;
    width: 100%;
  }

  form {
    .toggle-switch-field {
      width: 176px;
      height: 36px;
      border-radius: 4px;
      background-color: #252526;
      border: 1px solid rgba(133, 133, 133, 0.8);
      margin-bottom: 14px;
      margin-inline-end: 18px;
      margin-inline-start: auto;
      display: flex;
      justify-content: space-around;
      align-items: center;

      i {
        height: 24px;
      }

      span {
        font-size: 18px;
        font-weight: 400;
        color: #bdbdbd;
      }
    }

    .toggle-switch-active {
      i svg path {
        fill: #ffc700 !important;
      }

      span {
        color: white !important;
      }
    }

    mat-form-field {
      margin: 0 8px;
      //width: 16.4rem;

      input,
      textarea {
        font-size: 17px !important;
      }

      mat-select {
        font-size: 16px !important;
      }
    }

    // .text-area-field {
    //   width: 33.7rem !important;
    // }

    .save-btn {
      font-kerning: none;
      font-weight: 400;
      font-size: 20px;
      font-style: normal;
      line-height: 25px;
      text-transform: uppercase;
      width: 172px !important;
      height: 50px !important;
    }

    .save-btn:disabled {
      color: #000000 !important;
    }

    .mat-mdc-dialog-content {
      padding: 0 !important;
      text-align: center;
      margin-top: 16px;
      width: 100%;
    }

    .mat-mdc-dialog-actions {
      padding: 12px 0;
    }

    .form-header-controls {
      display: flex;
      justify-content: flex-start;
      padding: 15px 29px;

      button {
        font-size: 14px !important;
        width: fit-content !important;
        height: 32px !important;
        padding: 0 10px;
      }
    }

    .form-column-container {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: center;

      .form-controls-column {
        @include flex-center;

        flex-wrap: wrap;
        width: 47.4%;

        & > * {
          width: 95.8%;
          //margin: 0 8px;
        }
      }
    }

    .full-row-control {
      width: 95.8%;
    }

    .inline-form-control {
      //padding-inline: 30px;
      width: 93%;

      mat-form-field {
        width: 48% !important;
        margin-bottom: 8px !important;
      }
    }
  }

  h1,
  button,
  span {
    color: $white-color;
  }

  p,
  li {
    color: $light-gray;
  }

  mat-checkbox {
    width: 100%;
    text-align: start;

    .mat-checkbox-label {
      font-weight: normal;
    }
  }
}

.remove-button {
  position: absolute;
  bottom: 0;
  right: 35px;
  padding: 0;
  min-width: 0 !important;

  span {
    width: 24px !important;
    height: 32px !important;
    font-size: 2.2rem;
  }
}

.mat-dialog-content {
  max-height: 70vh;
  overflow: auto;
}

.mat-dialog-footer {
  padding-bottom: 26px;
  padding-top: 32px;
}

@media (max-width: $medium-screen) {
  mat-dialog-container {
    form {
      margin-bottom: 0;

      .form-header-controls {
        padding: 14px 8px;
      }

      .form-column-container {
        @include flex-col;
        @include flex-start;

        .form-controls-column {
          width: 100%;
        }
      }

      .inline-form-control {
        width: 100%;
        flex-direction: column;
        padding-bottom: 0;
      }
    }
  }

  .mat-dialog-footer {
    padding-bottom: 16px;
    padding-top: 18px;
  }

  .modal-title {
    max-width: 70%;
  }
}
