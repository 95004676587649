@import "src/assets/styles/global-style.scss";

.fs-fading {
  transition: $trn-dur-1 all;
}

@mixin fs-fade-in {
  @extend .fs-fading;

  opacity: 1;
  transform: scale(1);
}

@mixin fs-fade-out {
  @extend .fs-fading;

  opacity: 0;
  transform: scale(0);
}

.fs-filler {
  @include float-layer;
  @include full-size;

  z-index: 0;
  background-color: inherit;
  transition: $trn-dur-2 all;
  border-radius: inherit;
}

.fs-button {
  position: relative;
  transition: all 300ms;
  border-radius: 2px;
  box-sizing: none;
  border: none;
  background-color: transparent;
  font-size: 18px;
  padding: 16px;
  width: 100%;

  & > *:not(.fs-filler) {
    position: relative;
    z-index: 1;
  }

  & > span {
    display: block;
  }

  &.outline {
    border: 2px solid white;
  }
}

.fs-tooltip {
  @include fs-fade-out;

  position: absolute;
  top: -26px;
  left: 10px;
  z-index: 4;
  padding: 2px 6px;
  background-color: $gray-7-clr;
  border-radius: 4px;
  width: max-content;
  text-transform: initial;
  box-shadow: 2px 2px 4px 2px #00000026;

  span {
    font-size: 12px;
    color: $white-clr;
  }
}

.fs-control-disabled {
  filter: brightness(0.5) !important;
  cursor: default !important;

  .fs-tooltip {
    display: none;
  }
}

.fs-button:hover {
  .fs-tooltip {
    @include fs-fade-in;
  }

  .fs-filler {
    filter: brightness(0.8);
  }
}

.fs-shade-background-filter {
  background-color: $black-2-clr;
  backdrop-filter: blur(4px);
}

.fs-el-deactive {
  filter: brightness(0.8) grayscale(1) !important;
}

.fs-disabled-filter {
  filter: brightness(0.6) grayscale(1) !important;
}

.fs-el-disabled {
  @extend .fs-disabled-filter;

  cursor: default !important;
  pointer-events: none !important;
}

.fs-input-field-readonly {
  background-color: transparent !important;
  border: none !important;
}

.x-rotate-0 {
  transform: rotateX(0deg);
}

.x-rotate-180 {
  transform: rotateX(180deg);
}

.fs-btn-txt,
.fc-button {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  //line-height: $button-line-height !important;
  text-transform: uppercase !important;
}

//******************Draggable module styles***************
.draggable-list {
  position: relative;
  user-select: none;

  .draggable-item {
    display: block;
    cursor: move;
    user-select: none;
    transition: padding-top $trn-dur-2 $trn-animate;
  }

  .drag-back {
    transition: top 250ms cubic-bezier(0, 0, 0.2, 1),
      left 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  .drag-disabled {
    cursor: default;
  }
}

.dragging {
  user-select: none;
  position: fixed !important;
  background-color: $gray-15-clr;
  pointer-events: none;
  z-index: 99;
  top: 0;
  left: 0;
}
//*******************************************************

//******************Accordion module styles***************
.fs-accordion-active {
  margin-block: 10px !important;

  .fs-accordion-body {
    max-height: 4000px !important;
  }
}
//*******************************************************

.fs-control-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 56px;
  width: 94%;
  padding: 0;
  margin: 0 8px;
  margin-bottom: 20px;
}

$import-button-width: 84px;
.sim-mapping-item {
  height: 47px;
  padding: 0;
  overflow: initial;
  border: 1px solid $gray-18-clr;
  transition: height 250ms cubic-bezier(0, 0, 0.2, 1);

  .fs-list-r {
    font-size: 20px;
    height: 100%;

    span {
      color: $white-clr;
    }

    .import-button-wrapper {
      @include float-layer;
      @include flex-end;

      width: calc(100% + $import-button-width);

      button {
        border-radius: 2px;
        font-size: 1rem;
        text-transform: unset;
        height: min-content;
        width: $import-button-width;
      }
    }
  }
}

.dragging-ex-sim {
  @include flex-center;
  @extend .sim-mapping-item;
}

.fs-list-ui {
  margin-bottom: 2rem;
  width: 1200px !important;

  fs-list-item {
    position: relative;

    .fs-list-item {
      @extend .sim-mapping-item;
    }
  }

  .fs-list-r,
  .fs-list-h {
    & > div {
      @include full-size;
      @include flex-center;
    }
  }

  .fs-list-h {
    @include flex-center;

    height: 50px;
    background-color: $gray-15-clr;
    border: 1px solid $gray-16-clr;
    font-size: 18px;
    color: $gray-4-clr;
    position: sticky;
    top: 0;
    z-index: 1;

    & > div:not(:last-child) {
      border-inline-end: 1px solid $gray-16-clr;
    }
  }

  .fs-list-d-warpper {
    display: flex;

    fs-list {
      width: 100%;

      .fs-list-container {
        overflow-y: initial;
      }
    }
  }
}

.timepicker {
  .timepicker-dial {
    input {
      font-weight: bold !important;
    }

    .timepicker-period {
      padding-bottom: 10px !important;

      button {
        padding: 0 !important;
        height: 22px !important;
        min-height: unset !important;
      }
    }
  }

  .timepicker__body .clock-face {
    background-color: $white-clr !important;

    .clock-face__number {
      .mat-mdc-mini-fab {
        background-color: $primary-clr;
      }

      button {
        .mdc-button__label {
          color: black !important;
        }
      }
    }

    [mat-mini-fab].mat-void {
      background-color: unset;
    }

    button {
      border-radius: 50% !important;

      .mat-mdc-button-wrapper {
        font-weight: bold;
      }
    }
  }
}

ngx-mat-timepicker-dialog {
  .mat-mdc-dialog-actions div:nth-child(1) {
    button {
      background-color: $danger-clr !important;
    }
  }

  .mat-mdc-dialog-actions div:nth-child(2) {
    button {
      background-color: $primary-clr !important;
    }
  }

  .mat-mdc-dialog-actions button {
    width: 88px !important;
    margin-inline-start: 8px !important;
  }
}

.ngx-mat-mdc-timepicker-dialog {
  mat-dialog-container {
    padding: 24px !important;
    padding-top: 0 !important;
  }
}

.fs-default-input {
  background-color: transparent;
  border: none;
  box-sizing: unset;
  outline: none;
  font-size: inherit;
  color: inherit;
  line-height: inherit;
  font-weight: inherit;
  width: inherit;
  text-align: center;
  height: 100%;
}

.score-val {
  @extend .x24;
  @include flex-center;

  color: inherit;
  border-radius: 2px;
}

.fs-no-content-available {
  @include flex-center;
  @include full-size;

  p {
    font-size: 18px;
    margin: 16px;
    color: $gray-14-clr;
  }
}

.fs-form-field-icon {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
  padding-inline-start: 46px !important;

  .icon {
    @include float-layer;

    top: 6px;
    left: 6px;
  }
}

.fs-inline-content {
  @include flex-between;

  .inline-title {
    display: flex;
    font-size: 16px;
    color: $white-clr;
  }

  .inline-description {
    font-size: 12px;
    color: $gray-clr;
  }

  .inline-action {
    height: 30px;

    & button {
      height: inherit;
      width: 80px;
      font-size: 14px;
    }
  }
}

.locale-selection {
  .list-selector-header {
    i {
      margin-bottom: 0 !important;
    }
  }

  .overlay-content {
    padding: 8px !important;
    overflow-y: hidden !important;

    ul {
      li {
        a {
          @include flex-center;

          padding: 6px;
        }
      }
    }
  }
}

.fs-loading-content {
  position: relative;

  .fs-loading-container {
    @include float-layer;
    @include full-size;
    @include flex-center;

    z-index: 10;

    .loading-wrapper {
      display: flex;
      align-items: center;

      & > * {
        margin-inline: 4px;
      }

      & > span {
        margin-bottom: 4px;
      }
    }
  }
}

@media (max-width: $medium-screen) {
  .fs-inline-content {
    flex-wrap: wrap;

    .inline-action {
      width: 100%;
      text-align: end;
    }
  }
}

.fs-small-message {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: flex;

  &.error {
    color: $error-light;

    &::before {
      @extend .fsi-cir-error;
      @include bg-auto;

      margin-inline-end: 4px;
      content: "";
      display: block;
      width: 20px;
      height: 20px;
    }
  }

  &.success {
    color: white;

    &::after {
      @extend .fsi-checked;
      @include bg-auto;

      margin-inline-start: 4px;
      content: "";
      display: block;
      width: 20px;
      height: 20px;
    }
  }

  &.normal {
    color: $gray-6;
  }
}